//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//Component Import
  import Nav from "./components/Nav.vue";
  import NavMobile from "./components/NavMobile.vue";


export default {
  
  name: "App", //Exporting the main application view

  //Component Register
  components: {
    "app-nav": Nav,
    "app-nav-mobile": NavMobile
  },

  data: () => ({ 

   
  }),

   methods: {
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true;
                }
                else {
                    return false;
                }
      }
    }

  
};
