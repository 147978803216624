var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"color":"#282828"}},[_c('h2',{staticClass:"white--text ml-4 title-h2 "},[_vm._v("My Portfolio")]),_c('p',{staticClass:"body-text ma-4 portfolioText"},[_vm._v(" Front-end Development"),_c('br')]),_c('v-dialog',{attrs:{"persistent":"","width":_vm.width,"transition":"dialog-top-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false; _vm.pwa = false; _vm.characterChart = false; _vm.multiplayerGame = false; _vm.hamsterCareApp = false; _vm.kanjiSearchApp = false; _vm.exploreJapan = false; _vm.bookCover = false; _vm.vocabCards = false; _vm.beYou = false; _vm.flyerDesign = false}}},[_c('v-icon',{staticClass:"align-center white--text justify-space-around mx-auto"},[_vm._v("mdi-close")])],1),(_vm.pwa)?_c('div',[_c('pwa-project')],1):_vm._e(),(_vm.characterChart)?_c('div',[_c('character-chart')],1):_vm._e(),(_vm.multiplayerGame)?_c('div',[_c('multiplayer-game')],1):_vm._e(),(_vm.exploreJapan)?_c('div',[_c('explore-japan')],1):_vm._e(),(_vm.hamsterCareApp)?_c('div',[_c('hamster-care-app')],1):_vm._e(),(_vm.kanjiSearchApp)?_c('div',[_c('kanji-search-app')],1):_vm._e(),(_vm.bookCover)?_c('div',[_c('book-cover')],1):_vm._e(),(_vm.vocabCards)?_c('div',[_c('vocab-cards')],1):_vm._e(),(_vm.beYou)?_c('div',[_c('beYou')],1):_vm._e(),(_vm.flyerDesign)?_c('div',[_c('flyer-design')],1):_vm._e()],1),_c('div',{staticClass:"mb-4 pb-2"},[_c('v-row',{staticClass:"ml-2 mr-2",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/acorn-pwa-Tashii-Designs.png")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"pa-4 mb-4",attrs:{"color":"white"},on:{"click":function($event){_vm.dialog = true; _vm.pwa = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/kanji-search-Tashii-Designs.png")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"pa-4 mb-4",attrs:{"color":"white"},on:{"click":function($event){_vm.dialog = true; _vm.kanjiSearchApp = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/hamsterCareApp.png")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"pa-4 mb-4",attrs:{"color":"white"},on:{"click":function($event){_vm.dialog = true; _vm.hamsterCareApp = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/multiplayer-game-Tashii-Designs.png")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"pa-4 mb-4",attrs:{"color":"white"},on:{"click":function($event){_vm.dialog = true; _vm.multiplayerGame = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/explore-japan-Tashii-Designs.png")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"pa-4 mb-4",attrs:{"color":"white"},on:{"click":function($event){_vm.dialog = true; _vm.exploreJapan = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)],1)]}}])})],1)],1),_c('p',{staticClass:"body-text ma-4 portfolioText"},[_vm._v(" Visual Design and Branding"),_c('br'),_c('a',{staticClass:"no-underline",attrs:{"href":(_vm.publicPath + "Natasha-Joshi-Graphic-Design-Portfolio.pdf"),"download":"Natasha-Joshi-Graphic-Design-Portfolio"}},[_c('v-icon',{staticClass:"white--text",attrs:{"small":""}},[_vm._v("mdi-download")]),_vm._v(" Download my PDF Portfolio ")],1)]),_c('div',{staticClass:"mb-4 pb-2"},[_c('v-row',{staticClass:"ml-2 mr-2",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/BeYou-Health-Bar-Design-Tashii-Designs2.jpg")}}),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{on:{"click":function($event){_vm.dialog = true; _vm.beYou = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/hiragana-book-design-Tashii-Designs.jpg")}}),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{on:{"click":function($event){_vm.dialog = true; _vm.bookCover = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/vocab-cards-design-Tashii-Designs.jpg")}}),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{on:{"click":function($event){_vm.dialog = true; _vm.vocabCards = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/japanese-character-chart-design-Tashii-Designs.jpg")}}),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{on:{"click":function($event){_vm.dialog = true; _vm.characterChart = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/bjc-flyer.jpg")}}),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{on:{"click":function($event){_vm.dialog = true; _vm.flyerDesign = true}}},[_vm._v("More Info")])],1):_vm._e()])],1)]}}])})],1)],1)],1),_c('br'),_c('p',{staticClass:"body-text ma-4 portfolioText"},[_vm._v(" Logo Work ")]),_c('div',{staticClass:"mb-4 pb-2"},[_c('v-row',{staticClass:"ml-2 mr-2",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-card',[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/Interior-Design-logo-design-Tashii-Designs.jpg")}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': _vm.hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/Florist-logo-design-Tashii-Designs.jpg")}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': _vm.hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/Shimaguni-logo-design-Tashii-Designs.jpg")}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"9","sm":"6","md":"4","lg":"4"}},[_c('v-card',{staticClass:"portfolio-images",class:{ 'on-hover': _vm.hover }},[_c('v-img',{attrs:{"src":require("../assets/portfolio-page/Photography-logo-design-Tashii-Designs.jpg")}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }