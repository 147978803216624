//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
   dialog: false,
  }), 

//   methods: {
//     menuOpen() {
//         if(dialog = true){
//             return true;
//         }
//         else {
//             return false;
//         }
//     }
//   }
  
}
