//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap'

// const querystring = require("querystring");
const axios = require('axios').default;


export default {

  data: () => ({

    sent: false,
    valid: true,

    emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid', 
      ],

    form: {
      name:" ",
      email:" ",
      message:" ",
    },
      
  }), 

  mounted: function(){

    const tl = gsap.timeline();

    tl.add();

    tl.staggerFromTo('.title-h2, .notice, .contactForm', 1, {
      x:-200,
      y:0,
      opacity:0,
      ease: 'power2.inOut',
    }, {
      x:0,
      y:0,
      opacity:1,
      ease: 'power2.inOut',
    }, 0.1)


  },

  methods: {
    onSubmit(e) {
      e.preventDefault();

      axios.post("https://tashii.co.uk/mail.php", {
        "name": this.form.name,
        "email": this.form.email,
        "message": this.form.message

      }).then(() => {
          this.sent = true;
          console.log("success", this.form.message)
      }).catch(error =>  {
        console.log(error)
      })
    }
    }
  }
