//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import gsap from 'gsap'

export default {
  
  data: () => ({
    dialog: false,

    
  }),

  mounted: function(){

    const tl = gsap.timeline();

    tl.add();

    tl.staggerFromTo('.title-h2, .aboutCard, .mainSkills, .testimonials', 1, {
      x:-200,
      y:0,
      opacity:0,
      ease: 'power2.inOut',
    }, {
      x:0,
      y:0,
      opacity:1,
      ease: 'power2.inOut',
    }, 0.1)


  },

  computed: {

    // eslint-disable-next-line vue/return-in-computed-property
      width () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%'
          case 'sm': return '60%' 
          case 'md': return '30%'
          case 'lg': return '20%'
          case 'xl': return '20%'
     }
   },
},


}
