//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import gsap from 'gsap'
import pwaProject from '../components/pwa-project.vue'
import exploreJapan from '../components/explore-japan.vue'
import characterChart from '../components/character-chart.vue'
import multiplayerGame from '../components/multiplayer-game.vue'
import hamsterCareApp from '../components/hamsterCareApp.vue'
import kanjiSearchApp from '../components/kanjiSearchApp.vue'
import bookCover from '../components/book-cover.vue'
import vocabCards from '../components/vocab-cards.vue'
import beYou from '../components/beYou.vue'
import flyerDesign from '../components/shimaguni-flyers.vue'
export default {

   components: {
  
   "pwa-project": pwaProject,
   "character-chart": characterChart,
   "multiplayer-game": multiplayerGame,
   "hamster-care-app": hamsterCareApp,
   "explore-japan": exploreJapan,
   "kanji-search-app": kanjiSearchApp,
   "book-cover": bookCover,
   "vocab-cards": vocabCards,
   "beYou": beYou,
   "flyer-design": flyerDesign

  },

  data: () => ({
    dialog: false,
    pwa: false,
    characterChart: false,
    multiplayerGame: false,
    exploreJapan: false,
    bookCover: false,
    vocabCards: false,
    beYou: false,
    flyerDesign: false,
    portfolio: ['Front-end Development', 'Graphic Design & Branding'],
    publicPath: process.env.BASE_URL,
    cycle: false,
  }),

 
   mounted: function(){
      console.log(this.dialog)

     
    const tl = gsap.timeline();

    tl.add();

    tl.fromTo('.title-h2, .portfolioText', 1, {
      x:-200,
      y:0,
      opacity:0,
      ease: 'power2.inOut',
    }, {
      x:0,
      y:0,
      opacity:1,
      ease: 'power2.inOut',
    }, 0.1);

    tl.fromTo('.portfolio-images', 0.2, {
      transformOrigin:"50% 50%", //changes the transform origin to middle of element
      scaleY:.55,
      scaleX:.55,
      opacity:0,
      ease: 'back.inOut'
    }, {
      transformOrigin:"50% 50%", //changes the transform origin to middle of element
      scaleY:1,
      scaleX:1,
      opacity:1,
      ease: 'back.inOut'
    }, 0.1)

    

  },

  
  computed: {

    // eslint-disable-next-line vue/return-in-computed-property
      width () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '80%'
          case 'sm': return '80%' 
          case 'md': return '50%'
          case 'lg': return '50%'
          case 'xl': return '50%'
     }
   }
  },


}
