//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import gsap from 'gsap'

export default {

  data: () => ({
    
    bgImages: [
        {
            name: "swirl1",
            url:  require("../assets/bg-animation/swirl1.svg"),

        },

        {
            name: "swirl2",
            url:  require("../assets/bg-animation/swirl2.svg"),
        },

        {
            name: "smallFlower",
            url:  require("../assets/bg-animation/small-flower.svg"),
        },
        
    ],

    y: [],
    x: []
  }),

 mounted: function(){



    const bgtl = gsap.timeline();

    bgtl.add();

    bgtl.fromTo('.bgElements', 6, {
      x:0,
      y:0,
      opacity:0.2,
      transformOrigin:"50% 50%",
      rotation:360,
      ease: 'power0'
    }, {
      x:0,
      y:0,
      transformOrigin:"50% 50%",
      rotation:-360,
      repeat:-1,
      ease:'power0'
    }, 0.5)

     bgtl.fromTo('.fgElements', 3, {
      x:0,
      y:0,
      opacity:0,
      scaleY:.55,
      scaleX:.55,
      transformOrigin:"50% 50%",
    }, {
      x:0,
      y:0,
      scaleY:1,
      scaleX:1,
      transformOrigin:"50% 50%",
      opacity:1,
      repeat:-1,
      ease:'power0',
      yoyo:true
    }, 0.5)

 },

  created() {

    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;

     function getRandomNumber(min, max) {
        
        return Math.random() * (max - min) + min;
        
    }

    do {

        console.log("less")

        
        let randomTop = getRandomNumber(0, winHeight);
        let randomLeft = getRandomNumber(0, winWidth);

            
        console.log(randomTop +"px")
        console.log(randomLeft +"px")


        this.y.push(randomTop)
        this.x.push(randomLeft)
        console.log(this.y)

        getRandomNumber();

    } while (this.y.length <= 25 ) 


}
}

