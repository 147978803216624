//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import gsap from 'gsap'
import Background from "../components/background.vue";

export default {

  //Component Register
  components: {
   "background": Background,

  },

  data: () => ({
    dialog: false,

    
  }),



   mounted: function(){

    const tl = gsap.timeline();

    tl.add();

    tl.staggerFromTo('.titles, .contactButton, .flower-img', 1, {
      x:-200,
      y:0,
      opacity:0,
      ease: 'power2.inOut',
    }, {
      x:0,
      y:0,
      opacity:1,
      ease: 'power2.inOut',
    }, 0.5)


  },


   
}
